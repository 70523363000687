import {
  Box, Container, Flex,
  Text,
  Table, Thead, Tbody, Tr, Th, Td,
  Wrap,
  Spinner,
  useTheme,
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import MainLayout from '@src/components/layouts/main';
import { QUARTERS, TrafficLightColours } from '@src/modules/performance/shared/constants';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector } from '@transport-insights/uikit';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import { roundKpiValue } from '../../shared/helpers';

import IndicatorLegend from '../../components/IndicatorLegend';
import { useTemporaryTrafficManagementKpi } from '../../context/temporary-traffic-management-api-hooks';
import { ChartColours } from './components/charts/ChartColours';

const subheader = 'TTM cost as a percentage of NLTP';

function TemporaryTrafficManagement() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const theme = useTheme();
  const currentDatabase = useRecoilValue(currentDatabaseSelector);

  // KPI data
  const { data: ttmKpiData, isLoading: isLoadingKpi } = useTemporaryTrafficManagementKpi();

  const trendQuarters = [...new Set(QUARTERS.map((x) => `${x.label} 2022/23`))].sort();

  const isLoading = isLoadingKpi;

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);
  const helpSectionRef = useRef(null);
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header title="Temporary Traffic Management" subtitle={subheader} info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}>
            {ttmKpiData?.value && ttmKpiData?.value !== null && !isLoading && (
              <Flex align="center" ml={4}>
                <Box mr={3}>
                  <Text
                    colour={ttmKpiData?.value !== undefined ? 'gray.700' : null}
                    fontSize={36}
                    textAlign="center"
                  >
                    {roundKpiValue(ttmKpiData?.value, 7, 1)}
                    %
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray" fontSize="sm">
                    NLTP Expenditure (%)
                    <br />
                    <Text as="span" fontSize="xs" color="gray">
                      as at
                      {' '}
                      {QUARTERS.find((q) => q.id === parseInt(ttmKpiData?.quarter, 10))?.label}
                      {' '}
                      {ttmKpiData?.quadLockYear}
                    </Text>
                  </Text>
                </Box>
              </Flex>
            )}
          </ReportCard.Header>
          <ReportCard.Body>
            {isLoading && <Flex justify="center" align="center" h="300px"><Spinner color="brand.orange.500" size="lg" /></Flex>}
            {!isLoading && (
              <Wrap spacing={4}>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">TTM cost as a percentage of NLTP</Text>
                  <Chart
                    options={
                        {
                          chart: {
                            animations: {
                              enabled: false,
                            },
                          },
                          xaxis: {
                            categories: trendQuarters,
                            labels: {
                              offsetY: 10,
                            },
                          },
                          yaxis: [
                            {
                              forceNiceScale: true,
                              title: {
                                text: 'NLTP Expenditure (%)',
                              },
                              labels: {
                                formatter(val) {
                                  return `${val.toFixed(0)}%`;
                                },
                                offsetX: 30,
                              },
                            },
                          ],
                          grid: {
                            padding: {
                              left: 50,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'straight',
                            width: [2, 2, 0],
                          },
                          colors: [ChartColours.blue, ChartColours.black, ChartColours.lightgrey],
                          fill: {
                            colors: [ChartColours.blue, ChartColours.black, ChartColours.lightgrey],
                          },
                          markers: {
                            size: [4, 4, 0],
                            hover: {
                              size: 0,
                            },
                          },
                          legend: {
                            show: true,
                          },
                        }
                    }
                    series={
                        [
                          {
                            name: currentDatabase?.name,
                            type: 'line',
                            data: [67, 45, 56, 68],
                          },
                          {
                            name: 'Peer Group',
                            type: 'line',
                            data: [23, 25, 32, 28],
                          },
                          {
                            name: '25-75th Percentile',
                            type: 'area',
                            data: [45, 41, 48, 46],
                          },
                        ]
                      }
                    type="line"
                    width="100%"
                    height="400"
                  />
                </Box>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Confidence in TTM Cost</Text>
                  <Chart
                    options={
                      {
                        xaxis: {
                          labels: {
                            show: false,
                          },
                        },
                        yaxis: [
                          {
                            forceNiceScale: false,
                            min: 1,
                            max: 5,
                            labels: {
                              formatter(val) {
                                return `${val.toFixed(1)}`;
                              },
                            },
                          },
                        ],
                        dataLabels: {},
                        colors: [ChartColours.blue],
                        legend: {
                          show: true,
                          customLegendItems: [currentDatabase?.name],
                        },
                        toolbar: {
                        },
                        tooltip: {
                          enabled: true,
                          enabledOnSeries: [0],
                          marker: {
                            show: false,
                          },
                          custom({ seriesIndex, dataPointIndex, w }) {
                            const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                            return `<div style="padding: 10px">
                            <ul>
                            <li><b>${currentDatabase?.name}</b> 3</li>
                            <li><b>90th Percentile</b> ${data.y[4]}</li>
                            <li><b>75th Percentile</b> ${data.y[3]}</li>
                            <li><b>50th Percentile</b> ${data.y[2]}</li>
                            <li><b>25th Percentile</b> ${data.y[1]}</li>
                            <li><b>10th Percentile</b> ${data.y[0]}</li>
                            </ul></div>`;
                          },
                        },
                        plotOptions: {
                          boxPlot: {
                            colors: {
                              upper: theme.colors.gray[50],
                              lower: theme.colors.gray[50],
                            },
                          },
                        },
                      }
                    }
                    series={
                      [
                        {
                          type: 'boxPlot',
                          name: 'Confidence Range',
                          data: [{
                            x: 'Confidence',
                            y: [2, 2.8, 3.5, 4, 4.5],
                          }],
                        },
                        {
                          type: 'scatter',
                          name: currentDatabase?.name,
                          data: [{
                            x: 'Confidence',
                            y: [3],
                            fillColor: ChartColours.blue,
                          }],
                        },
                      ]
                    }
                    type="boxPlot"
                    width="100%"
                    height="400"
                  />
                </Box>
              </Wrap>
            )}
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={null}
          index={index}
          setIndex={setIndex}
        >
          <Box background="white" p={4} borderRadius={4}>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th width="25%">REPORT ELEMENT</Th>
                  <Th>NOTES</Th>
                  <Th width="20%">SOURCE</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">State Highway Pothole Repairs</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text mb={4}>
                      The indicator shows the colour grade for the percentage of potholes on all state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={[
                      { label: '>= 90%', color: TrafficLightColours.GREEN },
                      { label: '< 90%', color: TrafficLightColours.RED },
                    ]}
                    />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Targets for road categories</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">
                    % completed within 24 hours for high-volume, national and arterial roads
                  </Td>
                  <Td>
                    <Text mb={4}>
                      The colour grade for the percentage of potholes on high-volume, national, and arterial state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={[
                      { label: '>= 95%', color: TrafficLightColours.GREEN },
                      { label: '< 95%', color: TrafficLightColours.RED },
                    ]}
                    />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">
                    % completed within 24 hours for regional, primary collector, and secondary collector roads
                  </Td>
                  <Td>
                    <Text mb={4}>
                      The colour grade for the percentage of potholes on regional, primary collector, and secondary collector state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={[
                      { label: '>= 85%', color: TrafficLightColours.GREEN },
                      { label: '< 85%', color: TrafficLightColours.RED },
                    ]}
                    />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Network Outcome Contracts</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text>
                      The quarterly percentages of potholes on state highways within each Network Outcome Contract that were repaired within 24 hours.
                    </Text>
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Regions</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text fontSize="sm">
                      The quarterly percentages of potholes on state highways within each Region that were repaired within 24 hours.
                    </Text>
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </InfoContentCollapse>
      </Container>
    </MainLayout>
  );
}

export default TemporaryTrafficManagement;
